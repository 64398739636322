export enum ROUTES {
    HOME = '/',
    RESET = '/reset',
    CONFIG = '/teams/config',
    SIDE_PANEL = '/teams/sidepanel',
    CONFIRM_SHARE_RESET = '/teams/confirmsharereset/:id',
    RESET_STAGE_AUTH_SELECTOR = '/teams/resetstageselector',
    RESET_STAGE_AUTH = '/teams/a/resetstage',
    RESET_STAGE_UNAUTH = '/teams/u/resetstage',
    NOTIFICATIONSETTINGS = '/teams/notification/settings',
    RESET_IFRAME_PLAYER = '/teams/resetiframeplayer',
    RESET_PLAYER = '/teams/resetplayer/:resetId',
    RESET_VOTING_SETUP = '/teams/resetvotingsetup',
    RESET_VOTING_STAGE = '/teams/resetvotingstage',
    PERSONALIZED_MICROSTEPS = '/teams/microsteps/personalized',
    AUTH_RELAY = '/ms-teams/auth',
    EDIT_INTENTION = '/journeys/edit-intention',
    JOURNEY_MICROSTEPS = '/journeys/microsteps'
}
