import { sharing, people, FrameContexts, chat } from '@microsoft/teams-js'
import { Button } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactElement, memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useTeamsUrl from '../../../../shared/hooks/useTeamsUrl/useTeamsUrl'
import useSharingEnabled from '../../../hooks/useSharingEnabled/useSharingEnabled'
import useTeamsContext from '../../../../shared/hooks/useTeamsContext/useTeamsContext'

export interface ShareContentOnTeamsProps extends JSX.IntrinsicAttributes {
    button?: ReactElement<any, any>
    url: string
    message?: string
    ariaLabel?: string
    disableTracking?: boolean
    onShared?: (shareSuccessful: boolean) => void
    renderMessageAsDeeplink?: boolean
}

const messages = defineMessages({
    shareContent: {
        defaultMessage: 'Share through microsoft teams',
        description: 'Share through teams'
    }
})

const ShareContentOnTeams: React.FC<ShareContentOnTeamsProps> = ({
    button,
    url,
    message,
    ariaLabel,
    disableTracking,
    onShared,
    renderMessageAsDeeplink
}) => {
    const { formatMessage } = useIntl()
    const { enabled: sharingEnabled } = useSharingEnabled()
    const { loading, context } = useTeamsContext()
    const { renderedUrl, isUrlValid } = useTeamsUrl(
        url,
        renderMessageAsDeeplink,
        'people_picker_share',
        'msteams',
        disableTracking
    )

    const unSupportedContext = useMemo(
        () =>
            context?.page?.frameContext === FrameContexts.task ||
            context?.page?.frameContext === FrameContexts.stage,
        [context]
    )

    const onShare = useCallback(() => {
        if (unSupportedContext) {
            people
                .selectPeople()
                .then((people) => {
                    const sendChat =
                        people.length === 1
                            ? () =>
                                  chat.openChat({
                                      user: people[0].objectId,
                                      message: renderedUrl ?? message
                                  })
                            : () =>
                                  chat.openGroupChat({
                                      users: people.map(
                                          (person) => person.objectId
                                      ),
                                      message: renderedUrl ?? message
                                  })
                    sendChat()
                        .then(() => onShared?.(true))
                        .catch(() => onShared?.(false))
                })
                .catch(() => onShared?.(false))
        } else {
            sharing
                ?.shareWebContent({
                    content: [
                        {
                            type: 'URL',
                            url: renderedUrl,
                            message
                        }
                    ]
                })
                .then(() => onShared?.(true))
                .catch(() => onShared?.(false))
        }
    }, [renderedUrl, message, onShared, unSupportedContext])

    const shareButton = useMemo(
        () =>
            button ? (
                React.cloneElement(button, {
                    onClick: onShare
                })
            ) : (
                <Button
                    variant="contained"
                    onClick={onShare}
                    endIcon={<LeafIcon icon="microsoft" iconStyle="brands" />}
                    aria-label={
                        ariaLabel
                            ? ariaLabel
                            : formatMessage(messages.shareContent)
                    }
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Share to Teams"
                            description="share on teams button"
                        />
                    </CoreTypography>
                </Button>
            ),
        [button, ariaLabel, onShare, formatMessage]
    )

    if (
        !sharingEnabled ||
        loading ||
        (!isUrlValid && !renderMessageAsDeeplink)
    ) {
        return null
    }

    return shareButton
}

export default memo(ShareContentOnTeams)
