import { CssBaseline, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import FluentFullScreenLoading from '../components/elements/Fluent/FluentFullScreenLoading/FluentFullScreenLoading'
import { ROUTES } from './routes'

const Home = React.lazy(() =>
    import('../pages').then((module) => ({
        default: module.HomeWithContainer
    }))
)
// Due to be removed once its confirmed the configurable tab in the teams app manifest is removed
const TabConfig = lazy(() => import('../pages/liveShare/TabConfig'))
const PersonalizedMicrosteps = React.lazy(() =>
    import('../pages/personalizedMicrosteps').then((module) => ({
        default: module.PersonalizedMicrostepsWithContainer
    }))
)
const ResetIFramePlayer = React.lazy(() =>
    import('../pages/resetPlayer').then((module) => ({
        default: module.ResetIFramePlayerWithContainer
    }))
)
const ResetPlayer = React.lazy(() =>
    import('../pages/resetPlayer').then((module) => ({
        default: module.ResetIdPlayerWithContainer
    }))
)
const ConfirmShareReset = React.lazy(() =>
    import('../pages/liveShare').then((module) => ({
        default: module.ConfirmShareResetWithContainer
    }))
)
const SidePanel = React.lazy(() =>
    import('../pages/liveShare').then((module) => ({
        default: module.SidePanelWithContainer
    }))
)
const ResetStageAuthSelector = React.lazy(() =>
    import('../pages/liveShare/meetingStage').then((module) => ({
        default: module.ResetStageAuthSelectorWithContainer
    }))
)
const ResetStage = React.lazy(() =>
    import('../pages/liveShare/meetingStage').then((module) => ({
        default: module.ResetStageWithContainer
    }))
)
const ResetStateToS = React.lazy(() =>
    import('../pages/liveShare/meetingStage').then((module) => ({
        default: module.ResetStageToSRequiredWithContainer
    }))
)
const NotificationSettings = React.lazy(() =>
    import('../pages/notificationSettings').then((module) => ({
        default: module.NotificationSettingsWithProvider
    }))
)
const ResetVotingSetup = React.lazy(() =>
    import('../pages/liveShare/resetVoting').then((module) => ({
        default: module.ResetVotingSetupWithProvider
    }))
)
const ResetVotingStage = React.lazy(() =>
    import('../pages/liveShare/resetVoting').then((module) => ({
        default: module.ResetVotingStageWithContainer
    }))
)

const Router: React.FC = () => {
    const theme = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense fallback={<FluentFullScreenLoading />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.CONFIG}
                            component={TabConfig}
                        />
                        <ProtectedRoute
                            path={ROUTES.SIDE_PANEL}
                            component={SidePanel}
                        />
                        <ProtectedRoute
                            path={ROUTES.PERSONALIZED_MICROSTEPS}
                            component={PersonalizedMicrosteps}
                        />
                        <Route
                            path={ROUTES.RESET_IFRAME_PLAYER}
                            component={ResetIFramePlayer}
                        />
                        <Route
                            path={ROUTES.RESET_PLAYER}
                            component={ResetPlayer}
                        />
                        <Route
                            path={ROUTES.RESET_STAGE_AUTH_SELECTOR}
                            component={ResetStageAuthSelector}
                        />
                        <Route
                            path={ROUTES.RESET_STAGE_UNAUTH}
                            component={ResetStage}
                        />
                        <ProtectedRoute
                            path={ROUTES.CONFIRM_SHARE_RESET}
                            component={ConfirmShareReset}
                        />
                        <ProtectedRoute
                            path={ROUTES.RESET_STAGE_AUTH}
                            component={ResetStateToS}
                        />
                        <ProtectedRoute
                            path={ROUTES.NOTIFICATIONSETTINGS}
                            component={NotificationSettings}
                        />
                        <ProtectedRoute
                            path={ROUTES.RESET_VOTING_SETUP}
                            component={ResetVotingSetup}
                        />
                        <Route
                            path={ROUTES.RESET_VOTING_STAGE}
                            component={ResetVotingStage}
                        />
                        <ProtectedRoute path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
